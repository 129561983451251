@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

[id="root"] {
  overflow-x: hidden;
}

body {
  --navbar-height: 50px;
  margin: 0;
  background-color: #131313;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  --main_gradient: linear-gradient(45deg, #fe934c 0%, #fc686f  51%, #fe934c  100%);
}

.body_section {
  padding-top: var(--navbar-height);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  position: center;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll_indicator {
  background-image: linear-gradient(to top, gray 10%, transparent 80%);
  transition: all 450ms
}

.img_bg {
  filter: brightness(30%);
  object-fit: cover;
}

[id="collection_text"] {
  grid-row-start: 1;
  grid-column-start: 1;
}
[id="collection_items"] {
  grid-column-start: 1;
  grid-row-start: 1;
}
.media_parent {
  display: grid;
  grid-template-columns: 1fr;
}

.media_parent img {
  grid-row-start: 1;
  grid-column-start: 1;
}

[id="media_overview"] {
  grid-row-start: 1;
  grid-column-start: 1;
}
[id="media_cast"] {
  grid-column-start: 1;
  grid-row-start: 2;
}

.search-button {
  background-image: var(--main_gradient);
  margin: 0 auto;
  padding: 15px 15px;
}